import {
  articlesByVerticalQuery,
  detailArticleQuery,
  featuredArticlesByVerticalQuery,
  relatedArticlesQuery
} from './schema/articles.schema'

import {
  generateArticlesObjectFromContentful,
  generateNewsObjectFromContentful,
  isValidUrl
} from './helpers/functions'

class ArticlesService {
  constructor () {
    const { $apolloClient, $apolloClientPreview } = useNuxtApp()

    this.client = $apolloClient
    this.preview = $apolloClientPreview
  }

  fetchArticlesByVertical = async (vertical = [], limit = 12, skip = 0, preview = false) => {
    const { error, loading, data } = await this.client.query({
      query: articlesByVerticalQuery,
      variables: {
        vertical,
        limit,
        skip,
        preview
      }
    })

    if (loading || error) {
      return null
    }

    const items = data.articles.items.map(generateArticlesObjectFromContentful)

    return {
      ...data.articles,
      items
    }
  }

  fetchArticle = async (vertical = '', slug = '', preview = false) => {
    try {
      const { error, loading, data } = await this.client.query({
        query: detailArticleQuery,
        variables: {
          vertical,
          slug,
          limit: 1,
          preview
        }
      })

      if (loading || error) {
        return null
      }

      const [item] = data.articles.items
      const exist = data.articles.total > 0

      return {
        exist,
        ...item
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      throw(error)
    }
  }

  fetchRelatedArticles = async (vertical = '', slug = '', preview = false, limit = 4) => {
    const { error, loading, data } = await this.client.query({
      query: relatedArticlesQuery,
      variables: {
        vertical,
        limit,
        slug,
        preview
      }
    })

    if (loading || error) {
      return null
    }

    const items = data.articles.items.map(item => {
      const newObject = {
        title: item.title,
        slug: item.slug,
        mainContent: item.mainContent,
        featureImage: item.featureImage,
        author: {},
        vertical: ''
      }

      if (item.featureImage?.url && !isValidUrl(item.featureImage?.url)) {
        newObject.featureImage = {
          ...newObject.featureImage,
          url: `https:${item.featureImage.url}`
        }
      }

      return generateNewsObjectFromContentful(
        newObject,
        {
          layout: 'vertical'
        },
        true
      )
    })

    return {
      ...data.articles,
      items
    }
  }

  fetchFeaturedArticlesByVertical = async (vertical = [], limit = 12, skip = 0, preview = false) => {
    const { error, loading, data } = await this.client.query({
      query: featuredArticlesByVerticalQuery,
      variables: {
        vertical,
        limit,
        skip,
        preview
      }
    })

    if (loading || error) {
      return null
    }

    const items = data.articles.items.map(item => ({
      ...generateArticlesObjectFromContentful(item),
      isOvhc: !!item.isOvhc
    }))

    return {
      ...data.articles,
      items
    }
  }
}

export default ArticlesService
